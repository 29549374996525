import { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { OrderTypes } from "../../Constants/JsonObjects";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import sendPushNotification from "../../Services/sendPushNotification";
import createCallWaiter from "../../Services/createCallWaiter";
import updateCallWaiter from "../../Services/updateCallWaiter";
import getCallWaiter from "../../Services/getCallWaiter";
import { setCallWaiter, setCallWaiterFlag } from "../../store/ducks/callWaiter";
import { FEEDBACK_LINK, WaiterCallingStatus } from "../../Constants/Enums";
import { getTimeInUAE } from "../../helpers/utils";
import { setPDF } from "../../store/ducks/subCategories";
import SpaView from "../SpaView/SpaView";
import { setSelectedMainCategoryTab } from "../../store/ducks/mainCategories";
import { setShowSpaOptions } from "../../store/ducks/app";

const NavExtension = ({ items, order }) => {
  const canReview = JSON.parse(Cookies.get("userCookie")).canReview;
  const [isDeliveryOpen, setIsDeliveryOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [setSelectedOrderType] = useState(OrderTypes[0]);
  const [selectedOption, setSelectedOption] = useState("Scan Table");
  const [setSelectedFeature] = useState("Choose a location");
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [spa, setSpa] = useState(false);
  const [callWaiterText, setCallWaiterText] = useState("Service");
  const [waiterClassName, setWaiterClassName] = useState(
    "linear-gradient(135deg, white 0%, #ECE7DF 52%, #EAE6DD 56%, #FCF3DE 100%)"
  );

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const showSpaOptions = useSelector((state) => state.app.showSpaOptions);
  const table = useSelector((state) => state.table.listing);
  const concept = useSelector((state) => state.concept.listing);
  const callWaiter = useSelector((state) => state.callWaiter.callWaiter);

  const [optionItems, setOptionItems] = useState([]);

  useEffect(() => {
    if (showSpaOptions) {
      setSpa(true);
    }
  }, []);

  const handleDelivery = () => {
    setIsDeliveryOpen(!isDeliveryOpen);
    setIsOverlayVisible(!isOverlayVisible);
  };

  const handleLocation = () => {
    if (selectedOption === "Scan Table") {
      alert("QR");
    } else {
      setIsOverlayVisible(!isOverlayVisible);
      setIsLocationOpen(!isLocationOpen);
    }
  };

  const closeOverlayAndDelivery = () => {
    setIsOverlayVisible(false);
    setIsDeliveryOpen(false);
    setIsLocationOpen(false);
  };

  const handleSelectOrderType = (orderType) => {
    setSelectedOrderType(orderType);
    setIsOverlayVisible(false);
    setIsDeliveryOpen(false);
    if (orderType.type === "Dine In") {
      setSelectedOption("Scan Table");
    } else if (orderType.type === "Drive Thru") {
      setSelectedOption("Select Car");
      setSelectedFeature("Choose a car");
      setOptionItems(items.cars);
    } else if (orderType.type === "Delivery") {
      setSelectedOption("Select Location");
      setSelectedFeature("Choose a location");
      setOptionItems(items.locations);
    } else {
      setSelectedOption("Select Branch");
      setSelectedFeature("Choose a pick up location");
      setOptionItems(items.branches);
    }
  };

  const handleAddReview = () => {
    navigate("/review");
  };

  const closeReviewForm = () => {
    setIsReviewOpen(false);
  };

  const handleCallWaiter = async () => {
    dispatch(setCallWaiterFlag(true));
    setWaiterClassName("rgba(0, 0, 0, 0.3)");
    setCallWaiterText(t("stayTuned"));

    //send Push Notification
    const waiters = concept.receiveWaiters;
    const userObjects = waiters.map((userString) => JSON.parse(userString));
    const userIds = userObjects.map((userObject) => userObject.id);
    const res = await sendPushNotification(
      userIds,
      "Call Waiter",
      `Table ${table.tableName} is Calling`
    );

    /*
     * Get stored call waiter entry
     * Get last status
     * status === arrived, Then send new request, Else update it with higher count
     * */
    if (callWaiter !== null) {
      const newCallEntry = await getCallWaiter(callWaiter.id);
      dispatch(setCallWaiter(newCallEntry));

      //update count if status is still pending
      if (
        (newCallEntry !== undefined && newCallEntry.status) !==
        WaiterCallingStatus.arrived
      ) {
        await updateCallWaiter(
          newCallEntry.id,
          table.tableID,
          newCallEntry.count + 1,
          newCallEntry._version
        );
      } else {
        // create new entry
        const entry = await createCallWaiter(
          concept.id,
          table.tableID,
          table.tableName,
          1,
          getTimeInUAE()
        );
        if (entry) {
          dispatch(setCallWaiter(entry));
        }
      }
    } else {
      //create CallWaiter table entry for first time
      const entry = await createCallWaiter(
        concept.id,
        table.tableID,
        table.tableName,
        1,
        getTimeInUAE()
      );
      if (entry) {
        dispatch(setCallWaiter(entry));
      }
    }

    setWaiterClassName(
      "linear-gradient(135deg, white 0%, #ECE7DF 52%, #EAE6DD 56%, #FCF3DE 100%)"
    );
    setCallWaiterText("Service");
  };

  const openPdfLink = () => {
    dispatch(setSelectedMainCategoryTab(null));
    dispatch(setShowSpaOptions(false));

    const link = "PDFs/CIGAR+MENU+-+0824.pdf";

    dispatch(setPDF(link));
    navigate("/pdfview", { state: { pdf: link } });
  };

  const openSpaMenu = () => {
    dispatch(setSelectedMainCategoryTab(null));
    dispatch(setShowSpaOptions(true));

    setSpa(true);
  };

  const handleMenuClick = async (menu) => {
    dispatch(setPDF(menu.pdf));
    navigate("/pdfview", { state: { pdf: menu.pdf } });
  };

  return (
    <>
      <Grid item xs={12} style={{ width: "100%" }}>
        {/* {clicked && !canReview && (
          <DialogAlert
            handleClose={() => setClicked(false)}
            open={clicked}
            text={t(
              "You have already submitted your review. Thank you for your feedback!"
            )}
            title={t("Already Reviewed")}
          />
        )}
        <div className="CallWaiterContainer">
          <div
            className={!canReview ? "CallWaiterbtn__Disabled" : "CallWaiterbtn"}
            onClick={
              !canReview
                ? () => {
                    setClicked(true);
                  }
                : handleAddReview
            }
          >
            <AddCircleIcon />
            <Typography style={{ fontSize: 12, textTransform: "uppercase" }}>
              {t("addReview")}
            </Typography>
          </div>
        </div> 
        {concept?.needAssistance && (
          <div className="CallWaiterContainer">
            <div className={waiterClassName} onClick={handleCallWaiter}>
              <EmojiPeopleIcon />
              <Typography style={{ fontSize: 12, textTransform: "uppercase" }}>
                {t(callWaiterText)}
              </Typography>
            </div>
          </div>
        )}
        {concept?.spaBooking && (
          <div className="CallWaiterContainer">
            <div
              className={
                spa === "ComingSoon"
                  ? "CallWaiterbtn__Disabled"
                  : "CallWaiterbtn"
              }
              onClick={() => setSpa("ComingSoon")}
            >
              <SpaIcon />
              <Typography style={{ fontSize: 12, textTransform: "uppercase" }}>
                {t(spa)}
              </Typography>
            </div>
          </div>
        )} */}

        <Grid container spacing={1} rowSpacing={1}>
          {concept?.cigarMenu && (
            <Grid
              item
              xs={concept?.spaBooking ? 6 : 12}
              onClick={openPdfLink}
            >
              <div
                style={{
                  background: "#EFE9DD",
                  borderRadius: 10,
                  marginTop: ".5rem",
                  paddingTop: ".5rem",
                  paddingBottom: ".5rem",
                }}
              >
                <img
                  alt="logo"
                  src={require("../../assets/icons/cigar.png")}
                  style={{
                    width: 35,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
                <Typography
                  style={{
                    color: "black",
                    fontSize: 25,
                    fontFamily: `etihadBook${i18n.language}`,
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  {t("Cigar")}
                </Typography>
                <Typography
                  style={{
                    color: "black",
                    fontSize: 15,
                    fontFamily: `etihadBook${i18n.language}`,
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  {t("Menu")}
                </Typography>
              </div>
            </Grid>
          )}
          {concept?.spaBooking && (
            <Grid item xs={concept?.cigarMenu ? 6 : 12} onClick={openSpaMenu}>
              <div
                style={{
                  background: "#EFE9DD",
                  borderRadius: 10,
                  marginTop: ".5rem",
                  paddingTop: ".5rem",
                  paddingBottom: ".5rem",
                }}
              >
                <img
                  alt="logo"
                  src={require("../../assets/icons/spa.png")}
                  style={{
                    width: 35,
                    height: 35,
                    cursor: "pointer",
                  }}
                />
                <Typography
                  style={{
                    color: "black",
                    fontSize: 25,
                    fontFamily: `etihadBook${i18n.language}`,
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  {t("spa")}
                </Typography>
                <Typography
                  style={{
                    color: "black",
                    fontSize: 15,
                    fontFamily: `etihadBook${i18n.language}`,
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  {t("Menu")}
                </Typography>
              </div>
            </Grid>
          )}

          {/* Call Waiter */}
          <Grid item xs={12} marginTop={0.5}>
            {concept?.needAssistance && (
              <Grid
                container
                justifyContent={"center"}
                justifyItems={"center"}
                alignContent={"center"}
                alignItems={"center"}
                item
                xs={12}
                style={{
                  width: "100%",
                  height: 80,
                  background: waiterClassName,
                  borderRadius: 10,
                  border: "2px #D2B56F solid",
                }}
                onClick={() => {
                  handleCallWaiter();
                }}
              >
                <img
                  alt="logo"
                  src={require("../../assets/icons/specialRequest.png")}
                  style={{
                    width: 35,
                    height: 35,
                    cursor: "pointer",
                    marginLeft: ".5rem",
                    marginRight: ".5rem",
                  }}
                />
                <div
                  style={{
                    textAlign: "center",
                    color: "black",
                    fontSize: 25,
                    marginLeft: 4,
                    fontFamily: `etihadBook${i18n.language}`,
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  {t(callWaiterText)}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {spa && (
        <SpaView
          onSelect={handleMenuClick}
          toggleView={() => {
            setSpa(false);
            dispatch(setShowSpaOptions(false));
          }}
        ></SpaView>
      )}
    </>
  );
};

export default NavExtension;
