import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header.tsx";
import "./Cart.css";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ContactlessIcon from "@mui/icons-material/Contactless";
import getMenuItems from "../../Services/getMenuItems.js";
import {
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import { useLocation, useNavigate } from "react-router-dom";
import MainLoader from "../../Components/MainLoader/MainLoader.jsx";
import createOnlineOrder from "../../Services/createOnlineOrder.js";
import CartItem from "../../Components/CartItem/CartItem.jsx";
import Navbar from "../../Components/NavBar/NavBar";
import updateCart from "../../Services/updateCart.js";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../store/ducks/cart.js";
import { cacheData } from "../../Services/getCachedData.js";
import { Keys, OrderStatus, OrderType } from "../../Constants/Enums.js";
import sendPushNotification from "../../Services/sendPushNotification.js";
import DialogAlert from "../../Components/Dialog/DialogAlert.jsx";
import Cookies from "js-cookie";
import { formatDateToYYYYMMDDHHMMSS } from "../../helpers/utils.js";
import { setCurrentOrder } from "../../store/ducks/app.js";
import { getTimeInUAE } from "../../helpers/utils";

function Cart() {
  const TryItems = [
    {
      name: "Cookies",
      price: "30",
    },
    {
      name: "Cookies",
      price: "30",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const CartData = useSelector((state) => state.cart.listing);
  const dispatch = useDispatch();
  const [menuItemsData, setMenuItemsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [warning, setWarning] = useState(false);

  const { t, i18n } = useTranslation();

  const table = useSelector((state) => state.table.listing);
  const encryptedID = useSelector((state) => state.table.encryptedID);
  const concept = useSelector((state) => state.concept.listing);

  const [specialRequest, setSpecialRequest] = useState("");
  const [isCheckoutClicked, setIsCheckoutClicked] = useState(false);

  let vat = 1.14;

  useEffect(() => {
    const getCart = async () => {
      try {
        if (!CartData) {
          const cachedCart = JSON.parse(Cookies.get("cart"));
          if (cachedCart) {
            dispatch(setCart(cachedCart));
            setTotal(cachedCart.totalPrice);
            setSpecialRequest(
              cachedCart.specialRequest ? cachedCart.specialRequest : ""
            );
          }
        } else {
          setTotal(CartData.totalPrice);
          setSpecialRequest(CartData.specialRequest ?? "");
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    if (CartData.orderedItems.length === 0) {
      navigate(`/${encryptedID}`);
    } else {
      getCart();
    }
  }, []);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleAddItems = () => {
    navigate(`/${encryptedID}`);
  };

  const handleWarning = () => {
    setWarning(true);
  };

  const handleWarningClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setWarning(false);
  };

  const [success, setSuccess] = useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  const handleCheckout = async () => {
    try {
      if (total > 0 && selectedPaymentMethod === "") {
        handleWarning();
      } else {
        setIsCheckoutClicked(true);
        for (let i = 0; i < CartData.orderedItems.length; i++) {
          // Init with pending status
          CartData.orderedItems[i].pending = true;
          delete CartData.orderedItems[i].__typename;
        }
        const data = {
          cartID: CartData.id,
          conceptID: table.conceptID,
          userID: CartData.userID,
          userName: "Anonymous",
          orderedItems: CartData.orderedItems,
          specialRequest: specialRequest,
          OrderStatus: OrderStatus.pending,
          orderType: OrderType,
          orderTime: "now",
          totalPrice: total,
          tableID: table.tableID,
          tableName: table.tableName,
          createdAt: formatDateToYYYYMMDDHHMMSS(getTimeInUAE()),
          parentOrder: "none",
          childrenStatus: [],
        };
        const order = await createOnlineOrder(
          data.cartID,
          data.conceptID,
          data.userID,
          data.userName,
          data.orderedItems,
          data.specialRequest,
          data.OrderStatus,
          data.orderType,
          data.orderTime,
          data.totalPrice,
          data.tableID,
          data.createdAt,
          data.tableName,
          data.parentOrder,
          data.childrenStatus
        );
        //send PN when order is created

        const waiters = concept.receiveWaiters;
        const userObjects = waiters.map((userString) => JSON.parse(userString));

        // Extract the 'id' attribute from each object
        const userIds = userObjects.map((userObject) => userObject.id);
        const res = await sendPushNotification(
          userIds,
          "Online Order",
          `New order on Table ${table.tableName}`
        );

        const conceptID = concept.id;
        const cartID = CartData.id;
        const version = CartData._version;
        const newOrderedItems = [];
        const totalPrice = 0;
        const updated = await updateCart(
          cartID,
          conceptID,
          version,
          newOrderedItems,
          totalPrice
        );
        dispatch(setCart(updated));
        Cookies.set("cart", JSON.stringify(updated));

        await cacheData(Keys.CART, updated);

        setSuccess(true);
        dispatch(setCurrentOrder(order));
        Cookies.set("trackedOrder", JSON.stringify(order), { expires: 1 });
        navigate("/trackOrder", { state: { Order: order } });

        // setTimeout(() => {
        //   navigate("/trackOrder", { state: { Order: order } });
        // }, 1000);
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      {/* <Navbar/> */}
      {isLoading ? (
        <div className="Container centerLoading">
          <MainLoader />
        </div>
      ) : (
        <>
          {/* <Header Header={t("myCart")} addnew={false} setOpenForm={""} /> */}
          <div className="NavBarContainer">
            <Navbar items={[]} />
          </div>
          <div className="CartContainer">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={warning}
              autoHideDuration={3000}
              onClose={handleWarningClose}
            >
              <Alert
                onClose={handleWarningClose}
                severity="warning"
                sx={{
                  position: "fixed",
                  top: "16px",
                  right: "16px",
                }}
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleWarningClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                {t("paymentWarning")}
              </Alert>
            </Snackbar>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={success}
              autoHideDuration={3000}
              onClose={handleSuccessClose}
            >
              <Alert
                onClose={handleSuccessClose}
                severity="success"
                sx={{
                  position: "fixed",
                  top: "16px",
                  right: "16px",
                }}
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleSuccessClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                {t("orderSuccess")}
              </Alert>
            </Snackbar>

            {CartData.orderedItems?.map((orderedItem, index) => {
              const itemTotal = orderedItem.price;
              const itemOnly = orderedItem.price;
              return (
                <React.Fragment key={index}>
                  <CartItem
                    key={orderedItem.id}
                    ordereditem={orderedItem}
                    total={itemTotal}
                    itemOnly={itemOnly}
                    setTotal={setTotal}
                    cartPrice={total}
                    Cart={CartData}
                  />
                </React.Fragment>
              );
            })}

            {/* special requests */}
            <div className={total > 0 ? "TryThis" : "TryThis marginBottom5"}>
              <div
                style={{
                  flex: 1,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    color: "black",
                    fontSize: 20,
                    fontFamily: `etihadBook${i18n.language}`,
                    fontWeight: "600",
                    wordWrap: "break-word",
                  }}
                >
                  {" "}
                  {t("SpecialRequests")}
                </Typography>
                <TextField
                  id="outlined-multiline-flexible"
                  multiline
                  rows={4}
                  placeholder={t("cartPlaceholder")}
                  style={{
                    borderRadius: "50px",
                    width: "100%",
                    margin: "1rem auto",
                  }}
                  value={specialRequest}
                  onChange={(event) => setSpecialRequest(event.target.value)}
                />
              </div>
            </div>
            {openAlert && (
              <DialogAlert
                handleClose={() => setOpenAlert(false)}
                open={openAlert}
                title={t("ComingSoon")}
                text={t("ComingSoon!")}
              />
            )}

            <div className="Line"></div>

            {/* Payment methods */}
            {total > 0 && (
              <>
                <div className="TryThis">
                  <div
                    style={{
                      flex: 1,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                      width: "90%",
                    }}
                  >
                    <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("PaymentTitle")}
                    </Typography>
                    <RadioGroup
                      name="paymentMethod"
                      value={selectedPaymentMethod}
                      onChange={handlePaymentMethodChange}
                    >
                      <FormControlLabel
                        value="cash on delivery"
                        control={<Radio />}
                        label={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <AttachMoneyIcon />
                            <span style={{ marginLeft: "0.5rem" }}>
                              {t("cash")}
                            </span>
                          </div>
                        }
                      />
                      <FormControlLabel
                        value="credit card"
                        control={<Radio />}
                        label={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CreditCardIcon />
                            <span style={{ marginLeft: "0.5rem" }}>
                              {t("Credit")}
                            </span>
                          </div>
                        }
                      />
                      <FormControlLabel
                        value="card on delivery"
                        control={<Radio />}
                        label={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ContactlessIcon />
                            <span style={{ marginLeft: "0.5rem" }}>
                              {t("CardDelivery")}
                            </span>
                          </div>
                        }
                      />
                    </RadioGroup>
                  </div>
                </div>

                <div className="Line"></div>

                <div className="PaymentSummary">
                  <div
                    style={{
                      flex: 1,
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                      width: "90%",
                    }}
                  >
                    <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("PaymentSummary")}
                    </Typography>
                    <div className="PaymentSummaryInfo">
                      <div className="InfoRow">
                        <Typography className="LeftText">
                          {t("paymentSubtotal")}
                        </Typography>
                        <Typography className="RightText">
                          {t("AED")} {total}
                        </Typography>
                      </div>
                      <div className="InfoRow">
                        <Typography className="LeftText">
                          {t("serviceFee")}
                        </Typography>
                        <Typography className="RightText">
                          AED {t((total * vat - total).toFixed(2))}
                        </Typography>
                      </div>
                      <div className="InfoRow">
                        <Typography
                          className="LeftText"
                          style={{ fontWeight: "bold" }}
                        >
                          {t("paymentTotal")}
                        </Typography>
                        <Typography
                          className="RightText"
                          style={{ fontWeight: "bold" }}
                        >
                          AED {t((total * vat).toFixed(2))}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="FixedCart">
              <div className="FixedInfo">
                <button
                  className={`HalfButton ${
                    isCheckoutClicked ? "DisabledContainer" : ""
                  }`}
                  onClick={handleAddItems}
                  disabled={isCheckoutClicked}
                >
                  <Typography
                    style={{
                      color: "#C5941B ",
                      fontFamily: `etihadBook${i18n.language}`,
                    }}
                  >
                    {t("AddItems")}
                  </Typography>
                </button>
                <button
                  className={`HalfButton2 ${
                    isCheckoutClicked ? "DisabledContainer" : ""
                  }`}
                  onClick={handleCheckout}
                  disabled={isCheckoutClicked}
                >
                  <Typography
                    style={{
                      color: "white",
                      fontFamily: `etihadBold${i18n.language}`,
                    }}
                  >
                    {t("orderCheckout")}
                  </Typography>
                  {isCheckoutClicked && (
                    <span className="LoadingSpinner"></span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Cart;
