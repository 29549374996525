import React from "react";
import "./Header.css";
import { IconButton, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

function Header({ Header, addnew, setOpenForm }) {
  const table = useSelector((state: any) => state.table.encryptedID);
  var id;
  if (!table && Cookies.get("tableID")) {
    id = Cookies.get("tableID");
  } else {
    id = table;
  }

  return (
    <div className="HeaderContainer">
      {/* <Link to={`/${id}`}>
        <IconButton edge="end" aria-label="menu">
          <ArrowBack sx={{ color: "white" }} />
        </IconButton>
      </Link> */}
      <Typography
        style={{
          fontFamily: "arial, sans-serif",
          fontWeight: "bold",
          fontSize: 20,
          marginRight: "3rem",
          color: "white",
          textTransform: "uppercase",
        }}
      >
        {Header}
      </Typography>
      {addnew ? (
        <IconButton
          edge="start"
          aria-label="menu"
          onClick={() => {
            setOpenForm(true);
          }}
          style={{ color: "#007bff" }}
        >
          <Add />
        </IconButton>
      ) : (
        <Typography></Typography>
      )}
    </div>
  );
}

export default Header;
